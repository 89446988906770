<template>
  <div class="container">
    <h1 class="mig-headings">

      {{ $t("migrationProjects.pv.title") }}
    </h1>
    <p class="logo"><img src="../assets/logos/pvlogo_small.png" alt="pv" /></p> 
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <img
            src="../assets/mref/pinggeraPV.png"
            alt="image"
            class="img-thumbnail"
          />

          <p class="txtname">
            {{ $t("migrationProjects.pv.person1") }}
            <br />
            <br />
          </p>
          <p class="txt">
            {{ $t("migrationProjects.pv.text1") }} <br />
            <br />
          </p>
        </div>

        <div class="col-lg-6">
          <p class="txtname">
            {{ $t("migrationProjects.pv.person2") }}
            <br />
            <br />
          </p>
          <p class="txt">
            {{ $t("migrationProjects.pv.text2") }} <br />
            <br />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
</script>

<style scoped>
.col-lg-6 {
  text-align: center;
  margin-top: 70px;
}
.txtname {
  font-weight: 1000;
  color: #1d5097;
}
.logo {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}
.img-thumbnail {
  border-radius: 20px;
  float: none;
  width: 200px;
  margin-bottom: 20px;
}
.mig-headings {
  font-size: 20pt;
  color: #1d5097;
  text-align: center;
  font-weight: 550;
  text-transform: uppercase;
}
@media (max-width: 600px)  {
.mig-headings {
font-size: 17pt;
}

}

@media (max-width: 450px)  {
.mig-headings {
font-size: 13pt;
}

}
</style>
