<template>
  <div class="container">
    <h1 class="greytxt">{{ $t("impressum.title") }}</h1>
    <div>
      <h3 class="centeredtxt">
        <p class="border-bottom pb-3">
          SHARK GmbH <br />
          Business Consulting & IT-Solutions
        </p>
      </h3>
    </div>

    <div class="row">
      <div class="block">
        <h5 class="impressumHeading">{{ $t("impressum.heading_1") }}:</h5>
        <p class="border-bottom pb-3">
          {{ $t("impressum.subheading_1_1") }}: Lenaugasse 12 <br />
          {{ $t("impressum.subheading_1_2") }}: 3400 Weidling/Klosterneuburg
          <br />
          {{ $t("impressum.subheading_1_3") }}
        </p>
      </div>
      <div class="block">
        <h5 class="impressumHeading">{{ $t("impressum.heading_2") }}:</h5>
        <p class="border-bottom pb-3">
          {{ $t("impressum.subheading_1_1") }}: Wollzeile 25 / 27 <br />
          {{ $t("impressum.subheading_1_2") }}: 1010 Wien <br />
          {{ $t("impressum.subheading_1_3") }}
        </p>
      </div>
      <div class="block">
        <h5 class="impressumHeading">{{ $t("impressum.heading_3") }}:</h5>
        <p class="border-bottom pb-3">
          {{ $t("impressum.subheading_3_1") }}: +43 (0) 664 533 68 57 <br />
          {{ $t("impressum.subheading_3_2") }}: office@shark-soft.com <br />
          Internet: www.shark-soft.com
        </p>
      </div>
    </div>

    <div class="row">
      <div class="block">
        <h5 class="impressumHeading">{{ $t("impressum.heading_4") }}:</h5>
        <p class="border-bottom pb-3">
          {{ $t("impressum.subheading_4_1") }}: Klosterneuburg - Weidling<br />
          {{ $t("impressum.subheading_4_2") }}<br />
          {{ $t("impressum.subheading_4_3") }}: 102782a <br />
          {{ $t("impressum.subheading_4_4") }}: ATU 16134403 <br />
          {{ $t("impressum.subheading_4_5") }}
        </p>
      </div>
      <div class="block">
        <h5 class="impressumHeading">{{ $t("impressum.heading_5") }}:</h5>
        <p class="border-bottom pb-3">
          {{ $t("impressum.subheading_5_1") }} <br />
          {{ $t("impressum.subheading_5_2") }}
        </p>
      </div>
      <div class="block">
        <h5 class="impressumHeading">{{ $t("impressum.heading_6") }}:</h5>
        <p class="border-bottom pb-3">
          {{ $t("impressum.subheading_6_1") }} <br />
          {{ $t("impressum.subheading_6_2") }} <br />
          {{ $t("impressum.subheading_6_3") }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="block">
        <h5 class="impressumHeading">{{ $t("impressum.heading_7") }}:</h5>
        <p class="border-bottom pb-3">Christa Fürnkranz</p>
      </div>
      <div class="block" id="design">
        <h5 class="impressumHeading">{{ $t("impressum.heading_10") }}:</h5>
        <p class="border-bottom pb-3">
          Rafi Khan <br />
          Ivan Kokanovic
        </p>
      </div>
      <div class="block">
        <h5 class="impressumHeading">{{ $t("impressum.heading_9") }}:</h5>
        <p class="border-bottom pb-3">Tino Schulter www.schulter.cc</p>
      </div>
    </div>
  </div>

  <footer>
    <p>© 2020 Shark GmbH, Business Consulting & IT-Solutions</p>
  </footer>
</template>


<style scoped>


#design {
  margin-top: -24px;
}
footer {
  text-align: center;
}
.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0px;
}
.block {
  width: 400px;
  text-align: center;
}

.greytxt {
  font-size: 50pt;
  color: #1d5097;
  margin-top: 30px;
}

.impressumHeading {
  font-size: 15pt;
  color: #1d5097;
}
.centeredtxt {
  font-size: 20pt;
  color: #1d5097;
  font-weight: 500;
  margin-top: 0px;
}

@media (max-width: 1000px) {
  .greytxt {
    font-size: 32pt;
  }
}

@media (max-width: 600px) {
  .greytxt {
    font-size: 22pt;
  }

  .centeredtxt {
    font-size: 15pt;
  }
}
</style>