<template>
  <div class="container">
    <h1 class="mig-headings">
      {{ $t("migrationProjects.svs.title") }}
    </h1>
     <p class="logo"><img src="../assets/logos/svslogo.png" alt="svs" /></p> 
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <p class="txt">
            {{ $t("migrationProjects.svs.text") }} <br />
            <br />
          </p>
          <p class="txtname">
            {{ $t("migrationProjects.svs.person") }}
            <br />
            <br />
          </p>

          <img
            src="../assets/mref/svamueck.png"
            alt="image"
            class="img-thumbnail"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
</script>

<style scoped>
.col-lg-12 {
  text-align: center;
  
  margin-top:30px;
}
.txtname {
  font-weight: 1000;
  color: #1d5097;
}
.logo {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 40px;
}
.img-thumbnail {
  border-radius: 20px;
  float: none;
  width: 200px;
}
.mig-headings {
  font-size: 20pt;
  color: #1d5097;
  text-align: center;
  font-weight: 550;
  text-transform: uppercase;
  margin-top: 100px;
}
@media (max-width: 600px)  {
.mig-headings {
font-size: 17pt;
}

}
</style>
