<template>
  <nav
    id="navbar"
    class="navbar navbar-expand-lg navbar-light bg-light fixed-top"
    style="background-color: var(--sharks-color)"
  >
    <div class="container-fluid d-flex justify-content-around">
      <a
        class="navbar-brand"
        href="#"
        id="sharkbtn"
        @click="scrollToAnchorPoint('top')"
        data-bs-toggle="collapse"
        data-bs-target=".navbar-collapse.show"
      >
        <img src="@/assets/shark_logo.png" alt="" height="50" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a
              class="nav-link"
              aria-current="page"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
              @click="scrollToAnchorPoint('aboutUs')"
              >{{ $t("homeNav.nav_1") }}</a
            >
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              aria-current="page"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
              @click="scrollToAnchorPoint('solutions')"
              >{{ $t("homeNav.nav_2") }}</a
            >
          </li>

          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              id="navbarDarkDropdownMenuLink"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ $t("homeNav.nav_3") }}
            </a>
            <ul class="dropdown-menu">
              <li @click="scrollToAnchorPoint('cusRef')">
                <a
                  class="dropdown-item"
                  data-bs-toggle="collapse"
                  data-bs-target=".navbar-collapse.show"
                  >{{ $t("homeNav.nav_3_2") }}</a
                >
              </li>
              <li @click="scrollToAnchorPoint('migRef')">
                <a
                  class="dropdown-item"
                  data-bs-toggle="collapse"
                  data-bs-target=".navbar-collapse.show"
                  >{{ $t("homeNav.nav_3_1") }}</a
                >
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              aria-current="page"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
              @click="goToCareer()"
              >{{ $t("homeNav.nav_5") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              aria-current="page"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
              @click="goToGallery()"
              >{{ $t("homeNav.nav_6") }}</a
            >
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              aria-current="page"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
              @click="scrollToAnchorPoint('contact')"
              >{{ $t("homeNav.nav_4") }}</a
            >
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              aria-current="page"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
              @click="scrollToAnchorPoint('impressum')"
              >{{ $t("homeNav.nav_1_2") }}</a
            >
          </li>

          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              :v-model="$i18n"
              href="#"
              id="navbarDarkDropdownMenuLink"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ $i18n.locale }}
            </a>
            <ul class="dropdown-menu">
              <li
                v-on:click="changeLang('en')"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
                <a class="dropdown-item">EN</a>
              </li>
              <li>
                <a
                  class="dropdown-item"
                  v-on:click="changeLang('de')"
                  data-bs-toggle="collapse"
                  data-bs-target=".navbar-collapse.show"
                  >DE</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div id="container">
    <section ref="top" class="fullpage" id="home">
      <home></home>
    </section>

    <section ref="aboutUs" class="fullpage" id="au">
      <about-us> </about-us>
    </section>

    <section ref="solutions" class="fullpage" id="sol">
      <solutions> </solutions>
    </section>

    <section ref="cusRef" class="fullpage" id="cr">
      <customer-references> </customer-references>
    </section>

    <section ref="migRef" class="fullpage" id="mp">
      <migration-projects> </migration-projects>
    </section>

    <section ref="contact" class="fullpage" id="co">
      <contact></contact>
    </section>
    <section ref="impressum" class="fullpage" id="imp">
      <impressum> </impressum>
    </section>
  </div>
</template>

<script>
export default {
  methods: {
    scrollToAnchorPoint(refName) {
      const el = this.$refs[refName];
      el.scrollIntoView({ behavior: "smooth" });
    },

    goToCareer() {
      this.$router.push({ name: "Career" });
    },
    goToSolutions() {
      this.$router.push({ name: "Solutions" });
    },
    goToKontakt() {
      this.$router.push({ name: "Kontakt" });
    },
    goToCustomerReferences() {
      this.$router.push({ name: "CustomerReferences" });
    },
    goToMigrationProjects() {
      this.$router.push({ name: "MigrationProjects" });
    },
    goToAboutUs() {
      this.$router.push({ name: "AboutUs" });
    },
    goToGallery() {
      this.$router.push({ name: "Gallery" });
    },
    goToImpressum() {
      this.$router.push({ name: "Details" });
    },

    changeLang(lang) {
      this.$i18n.locale = lang;
      //this.$root.$i18n.locale = lang;
    },
  },
};
</script>



<style scoped>
#home {
  width: 100%;
  height: auto;
  margin-top: -30px;
}

#cr {
  width: 100%;
  height: auto;
}

#mp {
  width: 100%;
  height: auto;
  margin: auto;
}
#au {
  height: auto;
  width: 100%;
  margin: auto;
}
#co {
  height: 800px;
  width: 100%;
  margin: auto;
}
#imp {
  height: auto;
  width: 100%;
}
#container {
  width: 100%;
  position: fixed;
  top: 95px;
  bottom: 0;
  overflow: auto;
  overflow-x: hidden;
}
body {
  overflow: hidden;
}
.main {
  overflow-y: scroll;
}

.fullpage {
  padding-bottom: 0px;
}

.greytxt {
  font-size: 50pt;
  color: #1d5097;
}

#navbar {
  position: fixed; /* Make it stick/fixed */
  top: 0; /* Stay on top */
  width: 100%; /* Full width */
  transition: top 0.3s; /* Transition effect when sliding down (and up) */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

/* Style the navbar links */
#navbar a {
  float: center;
  display: block;
  color: #1d5097;
  text-align: center;
  padding: 15px;
  border-bottom-color: rgb(20, 126, 175);
}

#navbar a:hover {
  background-color: rgb(102, 179, 252);
  color: rgb(51, 75, 184);
}
#sharkbtn {
  margin-right: auto;
}

nav li:hover,
nav li:active {
  background-color:lightskyblue;
  cursor: pointer;
  
}
</style>
