import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import Toaster from "@meforma/vue-toaster"
import CareerHome from './views/Career-Home.vue'
import Kontakt from './views/Kontakt.vue'
import Gallery from './views/Gallery.vue'
import AboutUs from './views/AboutUs.vue'
import Solutions from './views/Solutions.vue'
import impressum from './views/impressum.vue'
import CusomerReferences from './views/Customer_References.vue'
import MigrationProjects from './views/Migration_Projects.vue'
import MigrationPv from './views/Migration-pv.vue'
import MigrationLv1871 from './views/Migration-lv1871.vue'
import MigrationSvs from './views/Migration-svs.vue'
import MigrationJusBrz from './views/Migration-jusbrz.vue'
import MigrationBundesheer from './views/Migration-bundesheer.vue'
import MigrationAmsBrz from './views/Migration-amsbrz.vue'
import MigrationNoe from './views/Migration-noe.vue'
import Home from './views/Home.vue'








// import { VueI18n } from 'vue-i18n';
import {
    createI18n
} from 'vue-i18n'

const messages = {
    en: {
        homeNav: {
            nav_1: "About Us",
            nav_1_1: "SHARK",
            nav_1_2: "Imprint",
            nav_2: "Solutions",
            nav_3: "References",
            nav_3_1: "Selected Migration Projects",
            nav_3_2: "Selected Customer References",
            nav_4: "Contact Us",
            nav_5: "Jobs",
            nav_6: "GALLERY"
        },
        home: {
            heading_1: "IT Migration and Re-Platforming",
            text_1: "SHARK's Migration-Architecture provides solutions for a fully automated transformation of the entire IT portfolio (e.g. applications, data, midleware, operating systems). The highly cost effective, risk adverse and fully automated migration process has proved itself with a success rate of 100% (in time and budget). ",
            heading_2: "Testmanagement",
            text_2: "The complete test of newly developed applications or migrated legacy systems on the new target platform has the highest priority. Out test tool is embedded within the SHARK Migration Architecture and guarantees an efficient execution.",
            heading_3: "Mobile Applications",
            text_3: "Our platform-independent business applications in the mobile Web technology can be connected easily to the existing applications, infrastructure and security. The mobile, platform and terminal-independent access to business relevant data provides you with a decisive competitive adavantage, the know-how of our specialists guarantees an efficient implementation. "
        },
        aboutUs: {
            title: "About SHARK",
            text_1: `With over two decades of experience in today's fast evolving market place, 
            we have successfully implemented customised commercial solutions as well as complex 
            large-scale Migration solutions for major clients dealing in the national and 
            international market place and public sector clients as well. SHARK is fortunate to cover 
            the full project life cycle (Project Management, Analysis, Design, Implementation, Test, 
            Training). The exposure of our consultants with a number of different operating practices has 
            helped them to gain knowledge of different methodologies (Agile Project Management 
            Methodologies, IPMA etc.). During the consultant process we use these different methodologies 
            but don't restrict ourselves to one type if the client already prefers their own. By providing 
            teams that incorporate the know-how of different business areas (e.g. banking, insurance, 
            trading, industry, public sector) our clients are satisfied with the knowledge of cost-effective 
            communiction and requirement understanding. In view of the type of projects and systems 
            implemented within some of our large-scale programs, security has been an important issue 
            both in the behaviour of our personnel and in the system built. `,
            text_2: `In order to remain competitive research and development has always been an 
            important issue. In recent years we focused on fully automated Legacy Modernization 
            (IT-Migration and Re-Platforming): With the development of the compiler-based SHARK 
            Migration Architecture we were able to fill a major gap in this area and are virtually 
            unrivaled within the European Market Place. The 100% success rate of our migration projects 
            speaks for itself. 
            `,

            text_3: `Rainer Froemmel`,
            text_4: `Christa Fuernkranz`,
            text_5: `Founders`
        },

        migrationProjects: {
            
            title: "Migration Projects",
            text_1: `SHARK has successfully implemented complex large-scale Migration solutions for large clients dealing in the national and international market place and public sector clients as well. 
            Migration projects combine the technical IT transformation as well as IT strategic and business consulting. 
            SHARK is fortunate to cover the full project life cycle by providing teams that incorporate the complete competency set.`,
            heading1: "The Austrian Federal Ministry of Justice / BRZ",
            heading2: "The Austrian Federal Ministry of Defense",
            heading3: "LV1871 (Life Insurance) in Munich",
            heading4: "Federal Pension Insurance",
            heading5: "Federal Labour Market Service",
            heading6: "Federal Social Insurance Authority for Business",
            heading7: "The Lower Austrian Federal Government",
            button: "Show more",

            jusbrz:{
                title:"The Austrian Federal Ministry of Justice / BRZ",
                text1: "\"The magic time-cost-quality triangle has been achieved impressively by SHARK (in coordination with the Federal Computing Center) "
                +"during the complete migration of the entire IT portfolio of the Austrian Federal Ministry of Justice. The remarkable SHARK Migration Architecture, "
                +"a professional project management and reporting have been the framework for this success within time and budget.\"",

                text2:"\"The complete migration of the entire IT-portfolio has been a milestone for the Austrian Federal Ministry of Justice, "
                +"however the project always remained straighforward and manageable. "
                +"It was the right decision to choose SHARK as a partner for the implementation of this path-breaking strategy. The unique and innovative IT-migration approach of SHARK, an Austrian consultancy, all proved successful.\"",

                text3:"\"The pressure of modernization and the reduction of vendor dependencies require solutions which provide a safe, less expensive and more flexible infrastracture. "
                +"The core legacy applications and data bases contain valuable business logic and know how representing years of development and evolution. "
                +"SHARK provided a fully automated migration of the entire IT portfolio to a modern platform while preserving this valuable business logic. The return on investment was compelling!\"", 

                person1:"Dr. Martin Schneider, CIO", 
                person2:"Dr. Josef Bosina, Head of the Presidential Department ",
                person3:"Dr. Peter Hubalek, Head of Procurement and Technical Infrastructure",
                imageTitle:"The Austrian Federal Minister of Justice was hosting a reception to honor the successful milestone-migration-project"
            },

            bundesheer: {

                title:"The Austrian Federal Ministry of Defense",
                imageHeading1:"Project Handover",
                imageHeading2:"The Command Support Center of the Austrian Federal Ministry of Defense was hosting a reception to honor the successful milestone-migration-project"
            },

            lv1871: {
                title: "LV1871 (Life Insurance) in Munich",
                heading: "Official Testimonial",
                text: " \"During our migration project with SHARK we opted for the agile project management method Scrum." +
                    "In addition to the highly transparent project progress we also covered most of the usually necessary change management: " +
                    "The project team designed the sprints together with the client. Due to the Daily Scrum meetings they are always up to date and get involved as a team for the common success." +
                    "They identify 100% with their project.\"",
                person: "Robert Weidinger, CIO"
            },

            pv: {

                title: "Federal Pension Insurance",

                text1: "\"The information technology of our institution supports the business logic and the core legacy applications. The complete mainframe migration of the hardware, software and database is similar to an open-heart-surgery of the Austrian Federal Pension Insurance. This surgery has been performed impressively succesful by SHARK within time and budget and and without any interruption of our daily business. Thanks to SHARK we can face the emerging IT and business requirements with increased agility at minimized IT costs and ressources.\"",
                text2: "\"The complete migration of the entire Siemens BS2000 mainframe environment by SHARK enabled IT cost savings by 60% while improving the overall IT performance at the same time. The major challenges of the strategic migration requirements and the tight time table have been mastered impressively by the effective teams of the Austrian Federal Pension Insurance and SHARK.\"",
                person1: "Dr. Winfried Pinggera, CEO",
                person2: "Herbert Hloupy, CIO"

            },
            svs:{
                title:"Federal Social Insurance Authority for Business",
                text:"\"The IT Migration using the remarkable SHARK Migration Architecture has more than proved itself, not only technically but also economically.\"",
                person:"Univ. Doz. Dr. Thomas Mück, Vice President"
            },

            amsbrz: {
                title:"Federal Labour Market Service / BRZ"
            },

            noe: {
                title:"The Lower Austrian Federal Government"
            }

        },
        contact: {
            title: "CONTACT",
            heading1: "Locations:",
            phone:"Phone:"
        },
        gallery: {
            heading_1: "Migration symposium",
            heading_2: "Reception at the Ministry of Justice",
            heading_3: "Reception at the Ministry of Defence"
        },
        impressum: {
            title: "Imprint",
            heading_1: "Registered office",
            subheading_1_1: "Address",
            subheading_1_2: "Place",
            subheading_1_3: "Country: Austria",
            heading_2: "Further location",
            heading_3: "Contact",
            subheading_3_1: "Phone",
            subheading_3_2: "Email",
            heading_4: "Commercial register information",
            subheading_4_1: "Company head office",
            subheading_4_2: "Commercial register court: regional court of Korneuburg",
            subheading_4_3: "Company register no",
            subheading_4_4: "VAT-ID",
            subheading_4_5: `Managing director: Mag. Rainer Froemmel, representing independently since 5.12.1991`,
            heading_5: "Development",
            subheading_5_1: `Founded in 1991 as a limited liability company`,
            subheading_5_2: "First recording: 5.12.1991",
            heading_6: "Miscellaneous",
            subheading_6_1: "Member of the Chamber of Commerce ",
            subheading_6_2: "Expert Group for Consulting and Information Technology",
            subheading_6_3: "Registered with KSV (Kreditschutzverband; Credit Protection Association of 1870) and D&B (Dun & Bradstreet) ",
            heading_7: "English Version",
            heading_8: "Version française",
            heading_9: "Cartoons",
            heading_10: "Design",

        },
        message: {
            home: 'HOME',
            ref: 'REFERENCES',
            solutions: 'SOLUTIONS',
            talent1: 'We need',
            talentHighlighted: 'your talent',

        },
        subheading: {
            mission: 'OUR MISSION',
            tools: 'OUR TOOLS'
        },
        heading: {
            mission: 'Provide Others With Supercharged IT Services',
            open: 'Open Vacancies',
            tools: "Widen The Horizon",
            flexi: "Flexible Hours",
            snacks: "Snacks and Coffee in the Kitchen",
            improvement: "Individual Improvement",
            equipment: "Optimal Equipment",
            projects: "Interesting Projects",
            environment: "Great Work Environment",
            location: "Our Locations",
            apply: "Apply For Your Future",
            students: "For Students",
            interns: "For Interns",
            employee: "Full-Time Employees",
            benefits: "Your Benefits"
        },
        text: {
            mission: `Our mission is to offer our customers a customised and specialised IT
            service. We customise our IT solutions to their needs and their
            priorities. We use new and promising tools to create and develop our
            products. Exciting challenges lie in our path and we are ready to take
            them on! Join us on our journey...`,
            tools1: `We are focused on creating great products and offering exceptional services to our customers. 
            This requires us to make changes and adapt to the newest and best.`,
            tools2: `Your unique skill set can increase our palette of services.`,
            flexi: "You have flexible working hours, which allows you to adjust your working hours.",
            snacks: "We have all sorts of healthy snacks and food in our kitchen and of course we've got coffee ;)",
            improvement: "We train and support our employees in their journey to excellence.",
            equipment: "We choose our equipment to best suit your needs when you at work.",
            projects: "We have great national and international partners for projects.",
            environment: "We are all committed to excellence and have positive values.",
            students: "You want to gain experience in the field of IT alongside your studies? We are prepared to give you a part-time job and are ready to train you. Our team will keep your studies in mind and train you accordingly. If you are studying computer science apply above.",
            interns: "Need a summer internship? You will be able to earn a great amount of practical experience with us. We will do our part in teaching and training you to the best of our capability. If you are a student of a school of higher technical education (HTL) and have the thirst to become better apply now above.",
            employee: "You want to be a part of our team? Well we are prepared to offer you a position. You can be part of an ambitious team and great projects."
        },
        references: {
            heading: "Our Projects",
            subheading: "Check Out Some of Our Previous Projects",
            porrtunity_heading: "Project: PORRtunity+",
            porrtunity_subheading: "A Project With Porr AG",
            porrtunity_text: "The main goal of this project was to migrate and redesign a legacy system, which is an existing database, to optimise its speed and ease of use.",
            brr_heading: "Project: IVV Migration to Java/Linux",
            brr_subheading: "A Project With Bundesrechenzentrum GmbH",
            brr_text: "The main goal of this project was to redesign and renew an older website's frontend and backend. Java was used to create the backend. The frontend was renewed to access the new backend and the design was modernised.",
            heading_2: "Customer References",
            subheading_2: "Selected Customer Reference"
        },
        solutions: {
            heading: "Our Solutions",
            listItem1: {
                heading: "IT Migration and Re-Platforming",
                card1_heading: "Moderate and scaleable migration risk and costs",
                card1_1: "1:1 migration, fully automated without any manual reworking",
                card1_2: "Proven project experience ranges from re-platforming to code conversion",
                card1_3: "Multiple generation cycles per day are provided",
                card1_4: "No interruption of daily business",
                card1_5: "Minimal Code Freeze Periods (all time record: 1 day!)",
                card1_6: "Automatic test generation tools are embedded: capture/replay , regression testing, performance benchmarking",
                card2_heading: "Solution completeness with the best of breed automated conversion",
                card2_1: "Transformation of 3 and 4 GL source languages (CSP/VAGEN, Smalltalk, Cobol, PL/I, BMS/CICS), Assembler, RPG into Java, C#, C/C++",
                card2_2: "Mainframe environments (z/OS, VSE, BS2000) to Linux, AIX, HP-UUX",
                card2_3: "Middleware substition (CICS, IMS, CTG, LU6.2, UTM) by SHARK Migration Architecture, Jboss, Websphere, SAP NetWeaver",
                card2_4: "Data Migration (Oracle, DB2, VSAM, SESAM, Leasy , DL1) to Oracle, DB2, SAP HANA, PostgreSQL, MySQL",
                card3_heading: "Contributed to skill evolution and employee development program",
                card3_1: "Low Learning curve by providing the original source code as a comment in front of the correspondent migrated code line",
                card3_2: "Graduates with a state of the art IT-education are able to maintain the new system without special training",
                card3_3: "No re-training for end users necessary! The look and feel of the applications stay the same with the enhancement of an intuitive graphic user interface",
                card4_heading: "Optimal Target Environment provides IT consolidation and simplification",
                card4_1: "A flexible Java / C# Framework is embedded within the SHARK Migration Architecture and integrates with the individual OO Architecture of the customer",
                card4_2: "Simpler, lower cost integration leveragging modern middleware and SOA",
                card4_3: "The complete integration into the underlying data base is self-evident"
            },
            listItem2: {
                heading: "Testmanagement",
                card1_heading: "Unit-Tests",
                card1_1: "During the Unit Test all generated source code parts are completely (C1-Test) technically tested.",
                card2_heading: "Regressionstests (Capture/Replay)",
                card2_1: "All user interactions within the production environmant are recorded during a defined period (capture) and reproduced on the new target platform (replay). During the evaluation phase it is verified whether errors occure, that means states of the GUI, database and output formats are queried.",
                card3_heading: "Integration Tests",
                card3_1: "Integration tests are compareable to the quality assurance phase of a project. This includes all testing activities on the complete system, including the connected interfaces. The tests are performed in a controlled test environment (controlled release handover, defined database situations).",
                card4_heading: "Performancetests using Flooder",
                card4_1: "The performance targets which have been defined during the analysis phase are reviewed and simulated with any number of users.",

            },
            listItem3: {
                heading: "EBM Suite",
                card1_heading: "Analysis Tool Set",
                card1_1: "Business process analysis, organisation analysis, data modelling and design for individual applications or for efficient implementation of any standard ERP system (e.g.: Oracle E-Business Suite)",
                card2_heading: "Configurator Tool Set",
                card2_1: "Tool for the definition and calculation of complex bills of materials – capable of full integration into your internal solutions (individual or standard ERP systems).",
                card3_heading: "Reporting Tool Set",
                card3_1: "Thanks to the individual design option and the integration of documents of any kind (project plans, logs, Word, Excel, etc.), the flexible Report Writer offers custom-made “up to date” documentation for all project participants, managers, departments, analysts and IT architects.",

            },
            listItem4: {
                heading: "EAI (Enterprise Application Integration)",
                card1_heading: "Reporting Tool Set",
                card1_1: "Our EAI-project for the Generali Group is an successful example for a seamless integration of different heterogeneous IT generations (NT, AIX, IBM S/390, OMVS).",
                card1_2: "The solution was based on multi tier architecture using distributed objects and included the following features:",
                card1_li1: "Integration of the existing legacy systems",
                card1_li2: "Secure communication (7/24 availability) by caching temporarily non available backend system (e.g. mainframe, UNIX)",
                card1_li3: "Mapping to convert different formats",
                card1_li4: "Interfaces to different applications",
                card1_li5: "Naming and security protocols",
                card1_li6: "Transaction processing, logging, backtracking and recovery",
                card1_li7: "XML-specified reusable metadata repository",
                card1_3: "An interface layer (resource pool) provided the database connection. Different database systems have been serviced e.g. DB2, Oracle, SQL-Server, Adabas ",
                card1_4: "There is communication across servers, which also allows full surveillance of remote servers and centralised software deployment. All available system builds are stored on the software server. The maintenance of the central system builds in the repository also follows XML-standard."
            },

        },
        jobs: {
            "job-1": "Java Full Stack Developer",
            "job-2": "UI/UX Developer",
            "job-3": "Part-Time Job",
            "job-4": "Internship",
            "job-5": "Unsolicited Application"
        }
    },
    de: {
        homeNav: {
            nav_1: "Über Uns",
            nav_1_1: "SHARK",
            nav_1_2: "Impressum",
            nav_2: "Lösungen",
            nav_3: "Referenzen",
            nav_3_1: "Ausgewählte Migrationsreferenzen",
            nav_3_2: "Ausgewählte Kundenreferenzen",
            nav_4: "Kontakt",
            nav_5: "Jobs",
            nav_6: "GALERIE"
        },
        home: {
            heading_1: "IT Migration und Re-Platforming",
            text_1: "SHARK's Migration Architecture bewährt sich bei der Migration des gesamten IT-Portfolios(Anwendungen, Daten, Middleware und Betriebssysteme). Der vollautomatische, kosten- und risikoschonende Migrationsprozess ist mehrfach produktionserprobt und im europäischen Raum praktisch konkurrenzlos. ",
            heading_2: "Testmanagement",
            text_2: "Der lückenlose Test neu entwickelter Applikationen bzw. migrierter Legacy Systeme auf der neuen Zielplattform hat höchste Priorität. Das in die SHARK Migration Architecture eingebettete Test-Tool garantiert eine effiziente Abwicklung.",
            heading_3: "Mobile Anwendungen",
            text_3: "Unsere Business Anwendungen in der mobilen Web Technologie können einfach an die bestehenden Anwendungen, Infrastrukturen und Security angebunden werden. Der mobile, plattform- und endgeräteunabhängige Zugriff auf unternehmensrelevante Daten liefert einen entscheidenden Wettbewerbsvorteil, das Know How unserer Spezialisten garantiert eine effiziente Umsetzung. ",

        },
        aboutUs: {
            title: "Über Shark",
            text_1: `SHARK zeichnet sich seit 1991 auf einem schnelllebigen Markt durch
            effiziente und lösungsorientierte Projektarbeit aus. Im Rahmen unserer
            Umsetzungsprojekte können wir alle Positionen innerhalb eines kompletten
            Projektlebenszyklus professionell besetzen (Projektmanagement, Analyse und
            Systementwurf, Fachliches- und Technisches Design, Umsetzung, Test,
            Schulung). Wir sind vertraut mit den verschiedenen Projektmanagment Ansätzen
            und richten uns nach den jeweiligen Standards unserer Kunden. Unsere
            langjährige Expertise deckt sowohl den 'konventionellen Bereich' als auch
            das Gebiet der 'Open-Source basierten Systeme' kompetent ab. Im Rahmen
            unserer Projektarbeit ist uns die Einbindung der Kundenmitarbeiter und der
            Wissenstransfer ein stetes Anliegen. Das selbstständige Warten und Betreuen
            der von uns in Produktion gebrachten Systeme durch die Kunden konnten wir in
            unseren Projekten immer ohne Probleme erreichen. Unser Beratungsportfolio
            wird durch fundierte Prozesskompetenz und Expertenwissen im Bereich Banken,
            Versicherungen, Handel, Industrie und öffentlicher Sektor abgerundet. Wir
            sind, sowohl im Umgang mit umfangreichen Datenmengen, als auch mit den
            besonderen Anforderungen an die Security in diesen Bereichen vertraut.`,
            text_2: `Um konkurrenzfähig zu bleiben investieren wir in Forschung und Entwicklung,
            dabei setzen wir in den letzten Jahren unseren Schwerpunkt auf Legacy
            Modernisierung (IT-Migration und Re-Platforming): Die von SHARK entwickelte,
            compiler basierte, SHARK Migration Architektur schließt eine wichtige
            Marktlücke auf dem Gebiet der vollautomatischen Legacy Modernisierung und
            ist im europäischen Raum weitgehend konkurrenzlos. Die 100% Erfolgsrate
            unserer Migrationsprojekte liefert den Beweis für diesen ausgereiften und
            produktionserprobten Ansatz.
            `,
            text_3: `Mag. Rainer Frömmel`,
            text_4: `Mag. Christa Fürnkranz`,
            text_5: `Gründer`
        },
        migrationProjects: {
            title: "Migrationsreferenzen",
            text_1: `Mit einer 100% Erfolgsrate hat SHARK komplexe, mehrstufige Migrationsprogramme, sowohl für nationale und internationale Kunden, 
                als auch für den öffentlichen Sektor, implementiert. 
                Migrationsprojekte vereinen sowohl die technische IT-Transformation als auch die strategische und organisatorische IT-Beratung. 
                SHARK deckt in seinen Projektteams alle Kompetenzen ab und kann das gesamte Migrationsvorhaben professionell betreuen.`,
            heading1: "Bundesministerium für Justiz / BRZ",
            heading2: "Bundesministerium für Landesverteidigung und Sport",
            heading3: "Lebensversicherung von 1871 a.G. München",
            heading4: "Pensionsversicherungsanstalt",
            heading5: "Arbeitsmarktservice",
            heading6: "Sozialversicherungsanstalt der Selbständigen",
            heading7: "Amt der NÖ Landesregierung",
            button: "Zeig mehr",

            
            jusbrz:{
                title:"Bundesministerium für Justiz / BRZ",
                text1: "\"Das magische Erfolgsdreieck Termine, "
                +"Kosten und Qualität, wurde bei dieser vollumfänglichen IT-Migration durch die Firma SHARK im Zusammenwirken mit der Bundesrechenzentrum GmbH in eindrucksvoller Weise erfüllt. "
                +"Die bemerkenswerte SHARKMigrationsarchitektur, effizientes Projektmanagement und ein lückenloses Berichtswesen bildeten den Kontext für die budget- und termintreue Zielerreichung.\"",

                text2:"\"Die vollumfängliche Migration der IT-Systeme stellt einen Meilenstein im österreichischen Justizministerium dar und blieb dennoch in einem schlanken und überschaubaren Rahmen. "
                +"Es war die richtige Entscheidung, für dieses strategisch wegweisende Projekt, die Firma SHARK als Partner zu wählen, ein österreichisches Unternehmen, "
                +"das sich mit einem völlig neuen Ansatz auf dem Gebiet der automatisierten IT-Migration als erfolgreich erweist.\"",

                text3:"\"Der Modernisierungsdruck zwingt zu Lösungen, die Abhängigkeiten von einzelnen Herstellern reduzieren, eine vergleichbar sichere und performante Infrastruktur bieten, "
                +"samt einer höheren Flexibilität und geringeren Kosten. In den zentralen Applikationen und Datenbanken manifestiert sich enormes Investitionskapital und das spezialisierte Know-How vieler Jahre. "
                +"Die vollautomatische Migration der Firma SHARK ermöglichte, sämtliche Komponenten, ohne Werteverlust, auf eine moderne Plattform überzuführen. Der erzielbare Return on Investment ist erfreulich kurz.\"", 

                person1:"Leitender Staatsanwalt, Dr. Martin Schneider Leiter IT / Pr 5 im Bundesministerium für Justiz", 
                person2:"Dr. Josef Bosina Sektionschef Bundesministerium für Justiz",
                person3:"Leitender Staatsanwalt, Dr. Peter Hubalek, Leiter IT-Beschaffung / Pr4 im Bundesministerium für Justiz",
                imageTitle:"Feierlicher Projektabschluss"
            },

            bundesheer: {

                title:"Bundesministerium für Landesverteidigung und Sport",
                imageHeading1:"Projektübergabe von SHARK",
                imageHeading2:"Feierlicher Projektabschluss"
            },
            
            lv1871: {
                title: "Lebensversicherung von 1871 a.G. München",
                text: "\"Im Rahmen unseres Migrationsprojekts, das wir mit der Unterstützung von SHARK GmbH durchführen, " +
                    "haben wir uns für die agile Projektmanagement Methode Scrum entschieden. " +
                    "Neben einer hohen Transparenz über den Projektfortschritt decken wir damit auch einen großen Teil der üblicherweise notwendigen Change Management-Maßnahmen ab: " +
                    "Die Projektmitarbeiter gestalten die Sprints gemeinsam mit dem Auftraggeber. Sie sind durch die Daily Scrum-Meetings immer auf dem Laufenden und engagieren sich als Team für den gemeinsamen Erfolg. Sie identifizieren sich zu 100% mit ihrem Projekt.\"",
                person: "Dipl. Math.Robert Weidinger, CIO der LV 1871 a. G. in München, Projektauftraggeber und Scrum Product Owner"
            },

            pv: {
                title: "Pensionsversicherungsanstalt",
                text1: "\"Die Informationstechnologie unseres Unternehmens unterstützt alle Geschäftsprozesse und Kernapplikationen. Eine so umfassende Host-Migration von Hardware, Software und Datenbanken entspricht einer „Operation am offenen Herzen“ der Pensionsversicherungsanstalt." +
                    "Diese Operation wurde von der Firma SHARK vorbildhaft, ohne Unterbrechung des laufenden Betriebs, termin- und budgettreu durchgeführt." +
                    "Dadurch hat uns die Firma SHARK in die Lage versetzt, zukünftigen Herausforderungen mit neuen technischen Möglichkeiten, bei signifikant geringerem Kosten- und Ressourcenaufwand zu begegnen.\"",
                text2: "\"Die Firma SHARK GmbH hat durch die vollumfängliche Migration der Siemens BS2000 Umgebung eine Einsparung der IT-Kosten für die Pensionsversicherungsanstalt in der Höhe von 60% ermöglicht, bei gleichzeitiger Verbesserung der Gesamtperformance. " +
                    "Die großen Herausforderungen der strategischen Migrationsvorgaben und der straffen Terminplänewurden durch die effektiven Teams der Pensionsversicherungsanstalt und SHARK eindrucksvoll gemeistert.\"",

                person1: "Dr. Winfried Pinggera, Generaldirektor der Pensionsversicherungsanstalt Projektsponsor der PVA-Migrationsprojekte",
                person2: "Dir. Herbert Hloupy Direktor Geschäftsbereich Informatik der Pensionsversicherungsanstalt Projektauftraggeber der PVA-Migrationsprojekte"

            },

            svs:{
                title:"Sozialversicherungsanstalt der Selbständigen",
                text:"\"Die Programmtransformation unter Verwendung dieses bemerkenswerten Ansatzes [SHARK Migration Architecture] hat sich nicht nur aus technischer, sondern auch - und vor allem - aus betriebswirtschaftlicher Sicht mehr als bewährt.\"",
                person:"Univ. Doz. Dr. Thomas Mück Generaldirektor Stellvertreter"
            },
            amsbrz: {
                title:"Arbeitsmarktservice / BRZ"
            },

            noe: {
                title:"Amt der NÖ Landesregierung"
            }

        },


        contact: {
            title: "KONTAKT",
            heading1:"Standorte:",
            phone: "Telefon:"
        },
        impressum: {
            title: "Impressum",
            heading_1: "Registrierter Sitz",
            subheading_1_1: "Anschrift",
            subheading_1_2: "Ort",
            subheading_1_3: "Land: Österreich",
            heading_2: "Weiterer Standort",
            heading_3: "Kontakt",
            subheading_3_1: "Telefon",
            subheading_3_2: "Email",
            heading_4: "Firmenbuchinformationen",
            subheading_4_1: "Firmensitz",
            subheading_4_2: "Firmenbuchgericht: Landesgericht Korneuburg",
            subheading_4_3: "Firmenbuchnummer",
            subheading_4_4: "UID-Nummer",
            subheading_4_5: `
                Geschäftsführer: Mag.Rainer Frömmel vertritt
                seit 5.12 .1991 selbständig `,
            heading_5: "Entwicklung",
            subheading_5_1: `
                Gründung erfolgte 1991 als Gesellschaft mit beschränkter Haftung `,
            subheading_5_2: "Erstprotokollierung: 5.12.1991 ",
            heading_6: "Sonstiges",
            subheading_6_1: "Mitglied der Wirtschaftskammer",
            subheading_6_2: "Fachgruppe Unternehmensberatung und Informationstechnologie ",
            subheading_6_3: "Registriert bei KSV (Kreditschutzverband von 1870) und D&B (Dun & Bradstreet)",
            heading_7: "English Version",
            heading_8: "Version française",
            heading_9: "Cartoons",
            heading_10: "Design"
        },
        gallery: {
            heading_1: "Migrationssymposion",
            heading_2: "Empfang beim Justizminister",
            heading_3: "Empfang beim Verteidigungsministerium"
        },
        message: {
            home: "STARTSEITE",
            ref: "REFERENZEN",
            solutions: "LÖSUNGEN",
            talent1: 'Wir brauchen',
            talentHighlighted: 'Dein Talent',
        },
        subheading: {
            mission: 'UNSERE MISSION',
            tools: 'UNSERE TOOLS'
        },
        heading: {
            mission: 'Biete Anderen erstklassige IT-Dienstleistungen',
            open: "Offene Stellen",
            tools: "Erweitere den Horizont",
            flexi: "Flexible Arbeitszeiten",
            snacks: "Snacks und Kaffee in der Küche",
            improvement: "Individuelle Verbesserung",
            equipment: "Optimale Ausstattung",
            projects: "Spannende Projekte",
            environment: "Großartiges Arbeitsumfeld",
            location: "Unsere Standorte",
            apply: "Bewerben für deine Zukunft",
            students: "StudententInnen",
            interns: "PraktikantInnen",
            employee: "Vollzeitmitarbeiter",
            benefits: "Ihre Vorteile"
        },
        text: {
            mission: `
                Unser Ziel ist es,
                unseren Kunden einen maßgeschneiderten und spezialisierten
                IT - Service zu bieten.Wir passen unsere IT - Lösungen an ihre Bedürfnisse und ihre Prioritäten an.
                Wir verwenden neue und vielversprechende Tools,
                um unsere Produkte zu erstellen und zu entwickeln.
                Aufregende Herausforderungen liegen auf unserem Weg und wir sind bereit,
                sie anzunehmen!`,
            tools1: `
                Wir konzentrieren uns darauf,
                großartige Produkte zu entwickeln und unseren Kunden außergewöhnliche Dienstleistungen anzubieten.
                Das erfordert von uns,
                dass wir uns verändern und an das Neueste und Beste anpassen.
                `,
            tools2: `
                Ihre einzigartigen Fähigkeiten können unsere Dienstleistungspalette erweitern.
                `,
            flexi: "Sie haben flexible Arbeitszeiten, die es Ihnen ermöglichen, Ihre Arbeitszeiten anzupassen.",
            snacks: "Wir haben alle Arten von gesunden Snacks und Lebensmitteln in unserer Küche und natürlich haben wir auch Kaffee ;)",
            improvement: "Wir schulen und unterstützen unsere Mitarbeiter auf ihrem Weg zu Spitzenleistungen.",
            equipment: "Wir wählen unsere Geräte so aus, dass sie Ihren Bedürfnissen bei der Arbeit am besten entsprechen.",
            projects: "Wir haben tolle nationale und internationale Partner für Projekte.",
            environment: "Wir sind alle zu Spitzenleistungen verpflichtet und haben positive Werte.",
            students: "Sie wollen neben Ihrem Studium Erfahrungen im IT-Bereich sammeln? Wir bieten Ihnen einen Nebenjob und sind bereit, Sie auszubilden. Unser Team wird Ihr Studium im Auge behalten und Sie entsprechend ausbilden. Wenn Sie Informatik studieren, bewerben Sie sich oben.",
            interns: "Sie brauchen ein Sommerpraktikum? Bei uns können Sie eine Menge praktischer Erfahrungen sammeln. Wir werden unseren Teil dazu beitragen, Sie nach besten Kräften zu unterrichten und auszubilden. Wenn Sie Schüler/in einer Höheren Technischen Lehranstalt (HTL) sind und den Drang haben, besser zu werden, bewerben Sie sich jetzt oben.",
            employee: "Sie wollen Teil unseres Teams werden? Dann sind wir bereit, Ihnen eine Stelle anzubieten. Sie können Teil eines ambitionierten Teams und großartiger Projekte sein."
        },
        references: {
            heading: "Unsere Projekte",
            subheading: "Sehen Sie sich einige unserer früheren Projekte an",
            porrtunity_heading: "Projekt: PORRtunity+",
            porrtunity_subheading: "Ein Projekt mit der Porr AG",
            porrtunity_text: "Das Hauptziel dieses Projekts war die Migration und Neugestaltung eines Altsystems, um dessen Geschwindigkeit und Benutzerfreundlichkeit zu optimieren.",
            brr_heading: "Projekt: IVV Migration to Java/Linux",
            brr_subheading: "Ein Projekt mit der Bundesrechenzentrum GmbH",
            brr_text: "Das Hauptziel dieses Projekts war die Neugestaltung und Erneuerung des Frontends und Backends einer älteren Website. Für die Erstellung des Backends wurde Java verwendet. Das Frontend wurde erneuert, um auf das neue Backend zugreifen zu können, und das Design wurde modernisiert.",
            heading_2: "Kundenreferenzen",
            subheading_2: "Ausgewählte Kundenreferenz"
        },
        solutions: {
            heading: "Unsere Lösungen",
            listItem1: {
                heading: "IT Migration and Re-Platforming",
                card1_heading: "Moderate und transparente Migrationskosten und Risken",
                card1_1: "1:1 Migration, vollautomatisch ohne manuelle Zwischenschritte oder Nachbearbeitung",
                card1_2: "Die Generierungsläufe können beliebig oft wiederholt werden!",
                card1_3: "Die vorbereitende Analyse ist eine reine Daten- und Codeanalyse und deshalb weitaus kürzer als bei Neuentwicklungen.",
                card1_4: "Die Wartung der Altsysteme ist während der gesamten Projektlaufzeit störungsfrei möglich?",
                card1_5: 'geringe Code Freeze Perioden ("all time record" 1 Tag!)',
                card1_6: "Integration automatischer Testtools für Capture/Replay, Regressionstests, Performance Benchmarking etc.",
                card2_heading: "SHARK bietet ausgereifte Migrationslösungen für Ihre Legacy Systeme",
                card2_1: "3 und 4 GL Sprachen (CSP/VAGEN, Smalltalk, Cobol, PL/I, BMS/CICS), Assembler, RPG nach Java, C#, C/C++",
                card2_2: "Mainframe Umgebungen (z/OS, VSE, BS2000) nach Linux, AIX, HP-UUX",
                card2_3: "Middleware Ablöse (CICS, IMS, CTG, LU6.2, UTM) durch SAP NetWeaver, Jboss, Websphere, SHARK Migration Architecture",
                card2_4: "Datenmigration (Oracle, DB2, VSAM, SESAM, Leasy, DL1) nach Oracle, DB2, SAP HANA, PostgreSQL",
                card3_heading: "Mitarbeiterfreundliche und kostenschonende Personalentwicklung",
                card3_1: "Erfreulich kurze Lernkurve für die Stammbelegschaft",
                card3_2: "Neue MitarbeiterInnen mit aktuellem IT-Ausbildungsstand können die Zielarchitektur ohne zusätzlichen Ausbildungsaufwand einfach betreuen und warten.",
                card3_3: "Die Umstellungen verlaufen für die EndanwenderInnen völlig unbemerkt, ohne Störung oder Unterbrechung des laufenden Produktionsbetriebs.",
                card4_heading: "Eine ausgereifte Zielarchitektur unterstützt die IT-Konsolidierung",
                card4_1: "Ein flexibles Java bzw. C# Framework ist in die SHARK Migrationsarchitektur eingebettet und wird an die individuelle OO Architektur der Kunden angebunden",
                card4_2: "Einfachere, kostengünstigere Integration in moderene Middleware und SOA",
                card4_3: "Vollständige Integration und Übernahme der zugrunde liegenden Datenbanken"
            },
            listItem2: {
                heading: "Testmanagement",
                card1_heading: "Unit-Tests",
                card1_1: "Im Unit Test werden alle generierten Quellcodeteile komplett (C1-Test) technisch durchgetestet.",
                card2_heading: "Regressionstests (Capture/Replay)",
                card2_1: "Alle Anwenderinteraktionen im Produktionsbetrieb werden während eines definierten Zeitraums aufgezeichnet (capture) und auf der neuen Zielplattform abgespielt (replay). Bei der Auswertung wird verifiziert, ob Fehler auftreten d.h. Zustände der GUI, Datenbankinhalte und Ausgabeformate werden abgefragt. ",
                card3_heading: "Integrationstests",
                card3_1: "Integrationstests werden auch mit der Qualitätssicherungsphase eines Projekts gleichgestellt. Dazu zählen alle Testaktivitäten am kompletten System inklusive der angebundenen Schnittstellen im Verbund. Die Tests werden in einer kontrollierten Testumgebung durchgeführt (kontrollierte Release-Übergabe, definierte Datenbanksituationen). ",
                card4_heading: "Performancetests mittels „Flooder“",
                card4_1: "Die im Rahmen der Analyse festgelegten Performance-Zielwerte werden überprüft und mit einer beliebigen Anzahl von Benutzern simuliert.",

            },
            listItem3: {
                heading: "EBM Suite",
                card1_heading: "Analysis Tool Set",
                card1_1: "Geschäftsprozessanalyse, Organisationsanalyse, Datenmodellierung und Design für individuelle Anwendungen oder zur effizienten Einführung eines beliebigen Standard-ERP-Systems (z.B.: Oracle E-Business Suite) ",
                card2_heading: "Configurator Tool Set",
                card2_1: "Werkzeug zur Definition und Kalkulation von komplexen Stücklisten - voll integrierbar in Ihre hauseigenen Lösungen (Individual- oder Standard-ERP-Systeme). ",
                card3_heading: "Reporting Tool Set",
                card3_1: "der flexible Report Writer bietet durch die individuelle Gestaltungsmöglichkeit und die Integration von Dokumenten aller Art (Projektpläne, Protokolle, Word, Excel etc.) eine maßgeschneiderte „up to date“ Dokumentation für alle Projektmitglieder, Manager, Fachbereich, Analytiker und IT-Architekten.",

            },
            listItem4: {
                heading: "EAI (Enterprise Application Integration)",
                card1_heading: "Reporting Tool Set",
                card1_1: "Unser EAI-Projekt für die Generali Gruppe ist ein gelungenes Beispiel für eine nahtlose Integration verschiedener heterogener IT-Generationen (NT, AIX, IBM S/390, OMVS).",
                card1_2: "Die Lösung basierte auf einer mehrstufigen Architektur mit verteilten Objekten und umfasste die folgenden Funktionen:",
                card1_li1: "Integration der bestehenden Altsysteme",
                card1_li2: "Sichere Kommunikation (7/24 Verfügbarkeit) durch Zwischenspeicherung vorübergehend nicht verfügbarer Backend-Systeme (z.B. Mainframe, UNIX)",
                card1_li3: "Mapping zur Konvertierung verschiedener Formate",
                card1_li4: "Schnittstellen zu verschiedenen Anwendungen",
                card1_li5: "Namensgebung und Sicherheitsprotokolle",
                card1_li6: "Transaktionsverarbeitung, Protokollierung, Backtracking und Wiederherstellung",
                card1_li7: "XML-spezifizierter Speicher für wiederverwendbare Metadaten",
                card1_3: "Eine Schnittstellenschicht (Ressourcenpool) stellt die Datenbankverbindung bereit. Es wurden verschiedene Datenbanksysteme bedient, z.B. DB2, Oracle, SQL-Server, Adabas  ",
                card1_4: "Es gibt eine serverübergreifende Kommunikation, die auch eine vollständige Überwachung von Remote-Servern und eine zentralisierte Softwareverteilung ermöglicht. Alle verfügbaren System-Builds werden auf dem Software-Server gespeichert. Die Pflege der zentralen System-Builds im Repository folgt ebenfalls dem XML-Standard."
            },

        },
        jobs: {
            "job-1": "Java Full Stack Entwickler",
            "job-2": "UI/UX Entwickler",
            "job-3": "Teilzeitjob",
            "job-4": "Praktikum",
            "job-5": "Initiativbewerbung"
        }
    }
}

const i18n = createI18n({
    locale: 'de', // set locale
    fallbackLocale: 'de', // set fallback locale
    messages, // set locale messages
});



const vue = createApp(App)
vue
    .use(Toaster)
    .use(router)
    .use(i18n)
    .component('career', CareerHome)
    .component('contact', Kontakt)
    .component('gallery', Gallery)
    .component('about-us', AboutUs)
    .component('solutions', Solutions)
    .component('impressum', impressum)
    .component('customer-references', CusomerReferences)
    .component('migration-projects', MigrationProjects)
    .component('migration-pv', MigrationPv)
    .component('migration-lv1871', MigrationLv1871)
    .component('migration-svs', MigrationSvs)
    .component('migration-jusbrz', MigrationJusBrz)
    .component('migration-bundesheer', MigrationBundesheer)
    .component('migration-amsbrz', MigrationAmsBrz)
    .component('migration-noe',MigrationNoe)
    .component('home', Home);
    
    










// add more functionality to myV3App

// now we're ready to mount
vue.mount('#app')


 //createApp(App).use(router).mount('#app')