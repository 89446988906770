<template>
    <transition name="scale" mode="out-in">
      <router-view/>
    </transition>
  
</template>

<script>
export default {};
// import * as mykey from '../src/assets/scripts/bootstrap.bundle.min.js';
// export default {
//   data() {
//     return {
//       message: `Hello ${mykey.MY_CONST}!` // Hello Vue.js!
//     }
//   }
// }
</script>

<style>
@import "../src/assets/styles/bootstrap.min.css";

a:hover,
a.router-link-active {
 /* border-bottom: 0.01px  #1d5097;
  */
}

#app {
  font-family: Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-size: 100%;
  height: 100%;
  margin-bottom: 0;
  bottom: 0;
  padding: 0;
}


body {
  background-color: var(--background-color);
  color: var(--font-color);
  height: 100%;
  margin-bottom: 0;
  bottom: 0;
  padding: 0;
  line-height: 1.5;
}

:root {
  --main-accent: #3c1874;
  --background-color: #ffffff;
  /* --background-color: #F5F5F5; */
  /* --background-color: #f8edeb; */
  --font-color: #000000;
  /* --font-color: #000000; */
  --sharks-color: #1d5097;
}

.greytxt {
  color: var(--main-accent);
  font-size: 1.2rem;
  text-transform: uppercase;
}

.container {
  padding: 1rem;
}

.title {
  font-size: 1.5rem;
}

.txt {
  font-size: 18px;
}

.centeredtxt {
  text-align: center;
}

.nav-item {
  text-transform: uppercase;
}

/* #mainContent {
  margin-top: 63px;
} */

@media only screen and (max-width: 600px) {
  .title {
    font-size: 24px;
  }

  .txt {
    font-size: 20px;
  }
}

.nav-link {
  cursor: pointer;
}

/* underline on hover animation */

@media screen and (max-height: 300px) {
  ul {
    margin-top: 40px;
  }
}
.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
}


.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>