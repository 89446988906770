<template>
  <body>
    <div class="container">
      <h1 class="greytxt">{{ $t("aboutUs.title") }}</h1>
      <p class="txt">
        {{ $t("aboutUs.text_1") }} <br />
        <br />
        {{ $t("aboutUs.text_2") }} <br />
        <br />
        {{ $t("aboutUs.text_3") }} <br />
        {{ $t("aboutUs.text_4") }} <br />
        {{ $t("aboutUs.text_5") }}
      </p>
    </div>
  </body>
</template>

<script>
</script>

<style scoped>
body {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../assets/backgrounds/test2.jpg);

  background-size: cover;
}
.greytxt {
  font-size: 50pt;
  color: white;
  margin-top: 30px;
}

.txt {
  color: white;
}

@media (max-width: 1000px) {
  .greytxt {
    font-size: 32pt;
  }

  .txt {
    font-size: 12pt;
  }
}

@media (max-width: 600px) {
  .greytxt {
    font-size: 22pt;
    margin-top: 10px;
  }

  .txt {
    font-size: 11pt;
  }
}
</style>
