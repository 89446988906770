<template>
  <div class="container">
    <h1 class="greytxt">{{ $t("migrationProjects.title") }}</h1>
    <p class="txt">
      {{ $t("migrationProjects.text_1") }}
    </p>
  </div>

  <div>
    <migration-jusbrz> </migration-jusbrz>
  </div>
  <div>
    <migration-pv> </migration-pv>
  </div>
  <div class="row">
    <div class="col-lg-6" id="collv">
      <migration-lv1871> </migration-lv1871>
    </div>
    <div class="col-lg-6" id="colsvs">
      <migration-svs> </migration-svs>
    </div>
  </div>
  <div><migration-bundesheer> </migration-bundesheer></div>
  <div>
    <migration-amsbrz> </migration-amsbrz>
  </div>
  <div>
    <migration-noe> </migration-noe>
  </div>
</template>



<script>
import MigrationBundesheer from "./Migration-bundesheer.vue";
import MigrationJusbrz from "./Migration-jusbrz.vue";
import MigrationLv1871 from "./Migration-lv1871.vue";
import MigrationPv from "./Migration-pv.vue";
export default {
  components: {
    MigrationPv,
    MigrationLv1871,
    MigrationJusbrz,
    MigrationBundesheer,
  },
};
</script>


<style scoped>
/* .row {
  height: 20vh;
} */

#colsvs {
  margin-top: -150px;
}

.txtname {
  font-weight: 1000;
  color: #1d5097;
}

.logo {
  text-align: center;
}
.img-thumbnail {
  border-radius: 15px;
  float: right;
}

.greytxt {
  font-size: 50pt;
  color: #1d5097;
  margin-top: 30px;
}

@media (max-width: 1000px) {
  .greytxt {
    font-size: 32pt;
  }
}

@media (max-width: 600px) {
  .greytxt {
    font-size: 22pt;
  }
}

@media (max-width: 400px) {
  .greytxt {
    font-size: 15pt;
  }
}
/* @media only screen and (max-width: 600px) {
  #mid {
    margin-top: 0rem;
  }
  .box {
    margin: 3px;
  }
} */
</style>