<template>
  <body style="background-image">
    <div class="container">
      <h1 class="contact-title">{{ $t("contact.title") }}</h1>

      <div class="row">
        <div class="block" id="email">
          <img src="../assets/icons/email.png" alt="icon" />
          <p />
          <h5 class="card-title">
            Email:
            <p />
            <p class="txt">
              <a href="mailto:office@shark-soft.com">office@shark-soft.com </a>
            </p>
          </h5>
        </div>
        <div class="block" id="location">
          <img src="../assets/icons/location.png" alt="icon" />
          <p />
          <h5 class="card-title">
            {{ $t("contact.heading1") }} <br />
            <p />
            Lenaugasse 12 <br />
            3400 Weidling/Klosterneuburg <br />
            <p />
            Wollzeile 25 / 27 (Mezzanin) <br />
            1010 Wien
          </h5>
        </div>

        <div class="block" id="phone">
          <img src="../assets/icons/phone.png" alt="icon" />
          <p />
          <h5 class="card-title">
            {{ $t("contact.phone") }}
            <p class="txt"></p>
            <p class="txt">+43 (0) 664 533 68 57</p>
          </h5>
        </div>
      </div>
    </div>
  </body>
</template>


<style scoped>
.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 120px;
}
.block {
  width: 300px;
  text-align: center;
}
.card-title {
  font-size: 16px;
}

.txt {
  font-size: 16px;
}
#location {
  color: white;
  text-align: center;
}

#phone {
  text-align: center;
  color: white;
  margin-top: 10px;
}

#email {
  text-align: center;
  color: white;
  margin-top: 10px;
}
.col-lg-4 {
  text-align: center;
  color: white;
}

body {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../assets/backgrounds/test2.jpg);

  background-size: cover;
}


a:link {
  color: white;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  font-size: 15pt;
}

.contact-title {
  font-size: 50pt;
  color: white;
  text-align: left;
  margin-top: 30px;
}

.txt {
  color: white;
}
@media (max-width: 1000px) {
  .contact-title {
    font-size: 32pt;
  }
}

@media (max-width: 600px) {
  .contact-title {
    font-size: 22pt;
  }
}
</style>