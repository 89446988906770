<template>
  <div class="container">
    <div id="popup" v-if="!this.hasAccepted">
      <div id="popup-dialog">
        <h3>Ihre Zustimmung auf die Benutzung von Daten und Cookies.</h3>
        <p>Wenn Sie auf dem Button unten klicken, erklären Sie sich lt. DSGVO mit der Nutzung von personenbezogenen Daten und der Verwendung aller Cookies einverstanden.</p>
      </div>
      <button class="btn btn-primary" @click="accept">
        Ich bin damit einverstanden.
      </button>
    </div>
    <div id="cookiebanner"></div>
    <div
      id="mainCarousel"
      class="carousel carousel-dark slide mb-3"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#mainCarousel"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#mainCarousel"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#mainCarousel"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
        <button
          type="button"
          data-bs-target="#mainCarousel"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
        <button
          type="button"
          data-bs-target="#mainCarousel"
          data-bs-slide-to="4"
          aria-label="Slide 5"
        ></button>
        <button
          type="button"
          data-bs-target="#mainCarousel"
          data-bs-slide-to="5"
          aria-label="Slide 6"
        ></button>
        <button
          type="button"
          data-bs-target="#mainCarousel"
          data-bs-slide-to="6"
          aria-label="Slide 7"
        ></button>
        <button
          type="button"
          data-bs-target="#mainCarousel"
          data-bs-slide-to="7"
          aria-label="Slide 8"
        ></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active" data-bs-interval="4000">
          <img
            src="../assets/slider/de/cart1.jpg"
            class="d-block w-100"
            alt="..."
          />
        </div>
        <div class="carousel-item" data-bs-interval="4000">
          <img
            src="../assets/slider/de/cart2.jpg"
            class="d-block w-100"
            alt="..."
          />
        </div>
        <div class="carousel-item" data-bs-interval="4000">
          <img
            src="../assets/slider/de/cart3.jpg"
            class="d-block w-100"
            alt="..."
          />
        </div>
        <div class="carousel-item" data-bs-interval="4000">
          <img
            src="../assets/slider/de/cart4.jpg"
            class="d-block w-100"
            alt="..."
          />
        </div>
        <div class="carousel-item" data-bs-interval="4000">
          <img
            src="../assets/slider/de/cart5.jpg"
            class="d-block w-100"
            alt="..."
          />
        </div>
        <div class="carousel-item" data-bs-interval="4000">
          <img
            src="../assets/slider/de/cart6.jpg"
            class="d-block w-100"
            alt="..."
          />
        </div>
        <div class="carousel-item" data-bs-interval="4000">
          <img
            src="../assets/slider/de/cart7.jpg"
            class="d-block w-100"
            alt="..."
          />
        </div>
        <div class="carousel-item" data-bs-interval="4000">
          <img
            src="../assets/slider/de/cart8.jpg"
            class="d-block w-100"
            alt="..."
          />
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#mainCarousel"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#mainCarousel"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <div class="row mt-3">
      <div class="col-md mx-2">
        <div class="migration">
          <img
            src="../assets/cartoonMigration/mig1v2.png"
            alt=""
            class="img-fluid"
          />
          <h2 class="title">{{ $t("home.heading_1") }}</h2>
          <p class="txt">{{ $t("home.text_1") }}</p>
        </div>
      </div>
      <div class="col-md mx-2">
        <div class="migration">
          <img
            src="../assets/cartoonMigration/mig6_v2.png"
            alt=""
            class="img-fluid"
          />
          <h2 class="title">{{ $t("home.heading_2") }}</h2>
          <p class="txt">{{ $t("home.text_2") }}</p>
        </div>
      </div>
      <div class="col-md mx-2">
        <div class="migration">
          <img
            src="../assets/barPictures/mobile_v2.png"
            alt=""
            class="img-fluid"
          />
          <h2 class="title">{{ $t("home.heading_3") }}</h2>
          <p class="txt">{{ $t("home.text_3") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        hasAccepted: false
      }
    },
    mounted() {
      console.log("mounted");
      this.getAccepted()
    },
    methods: {
      accept() {
        localStorage.setItem('accepted', true);
        console.log("clicked")
        this.getAccepted()
      },
      getAccepted() {
        this.hasAccepted = localStorage.getItem('accepted')
      }
  }
}
</script>

<style scoped>
.carousel-inner {
  margin-bottom: 0px;
}
.carousel-item img {
  max-height: 400px;
  max-width: 700px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.migration {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.col-md img {
  margin-bottom: 1rem;
}

.title {
  color: #1d5097;
}

.txt {
  font-size: 17px;
}


#popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow:0 0 0 1600px rgba(0,0,0,0.65);/* dark around it */
  display: flex;
  flex-direction: column;
  z-index: 7;
  min-width: 350px;
}
</style>