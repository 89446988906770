<template>
  <div class="container">
    <h1 class="mig-headings">
      {{ $t("migrationProjects.noe.title") }}
    </h1>
    <p class="logo"><img src="../assets/logos/noelogo.png" alt="noe" /></p>
  </div>

  <table class="table table-bordered">
    <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Source environment: IBM OS/390</th>
        <th scope="col">Target Environment: Windows</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">Hardware</th>
        <td>IBM z/OS 1.11</td>
        <td>Intel basiert</td>
      </tr>
      <tr>
        <th scope="row">Operating System</th>
        <td>OS/390</td>
        <td>Windwos R2008 Server</td>
      </tr>
      <tr>
        <th scope="row">TP Monitor</th>
        <td>CICS TS v 3.2</td>
        <td>Microfocus CICS/IMS (MTO, HCO)</td>
      </tr>
      <tr>
        <th scope="row">Database</th>
        <td>DB2 z/OS v 9.1</td>
        <td>DB2 UDB 9.7</td>
      </tr>
      <tr>
        <th scope="row">Files</th>
        <td>VSAM</td>
        <td>Windows Filesystem</td>
      </tr>
      <tr>
        <th scope="row">Language</th>
        <td>CSP / VAG / PL/I, Cobol unter z/OS</td>
        <td>C#, PL/I, Cobol unter Microfocus und SHARK PL/I Cross Compiler</td>
      </tr>
      <tr>
        <th scope="row">User Interface</th>
        <td>3270</td>
        <td>C# und 3270 Microfocus Server</td>
      </tr>
      <tr>
        <th scope="row">Cmd Language</th>
        <td>JCL</td>
        <td>KSH</td>
      </tr>
      <tr>
        <th scope="row">Job schedule</th>
        <td>TWS(Tivoli Workload Scheduler)</td>
        <td>UC4: Global, Ksh</td>
      </tr>
      <tr>
        <th scope="row">Sort tool</th>
        <td>JCL, IBM DFSORT</td>
        <td>C-Addons SHARK Migration Architecture</td>
      </tr>
    </tbody>
  </table>
</template>


<style scoped>
.mig-headings {
  font-size: 20pt;
  color: #1d5097;
  text-align: center;
  font-weight: 550;
  text-transform: uppercase;
  margin-top: 50px;
}

.logo {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 50px;
}

table {
  width: 50%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 600px)  {
.mig-headings {
font-size: 17pt;
}

}
</style>