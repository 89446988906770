<template>
  <div class="container">
    <p class="logo">
      <img src="../assets/logos/justizlogo.png" alt="justiz" />
    </p>
    <h1 class="mig-headings">
      {{ $t("migrationProjects.jusbrz.title") }}
    </h1>
    <p class="logo"><img src="../assets/logos/brzlogo3.png" alt="brz" /></p>

    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <img
            src="../assets/mref/schneiderBMJ.png"
            alt="image"
            class="img-thumbnail"
          />

          <p class="txtname">
            {{ $t("migrationProjects.jusbrz.person1") }}
            <br />
            <br />
          </p>
          <p class="txt">
            {{ $t("migrationProjects.jusbrz.text1") }} <br />
            <br />
          </p>
        </div>
        <div class="col-lg-4" id="cont1">
          <img
            src="../assets/mref/hubalekBMJ.png"
            alt="image"
            class="img-thumbnail"
          />
          <p class="txtname">
            {{ $t("migrationProjects.jusbrz.person3") }}
            <br />
            <br />
          </p>
          <p class="txt">
            {{ $t("migrationProjects.jusbrz.text3") }} <br />
            <br />
          </p>
        </div>

        <div class="col-lg-4">
          <img
            src="../assets/mref/bosinaBMJ.png"
            alt="image"
            class="img-thumbnail"
          />
          <p class="txtname">
            {{ $t("migrationProjects.jusbrz.person2") }}
            <br />
            <br />
          </p>
          <p class="txt">
            {{ $t("migrationProjects.jusbrz.text2") }} <br />
            <br />
          </p>
        </div>
        <h2 class="imgHeading">{{ $t("migrationProjects.jusbrz.imageTitle") }}</h2>

        <img src="../assets/mref/justizeinladung.png" alt="image" />
      </div>
    </div>
  </div>
</template>


<script>
</script>

<style scoped>
#cont1 {
  margin-top: 20px;
}

h2 {
  text-align: center;
  font-size: 20pt;
  color: #1d5097;
  font-weight: 550;
  margin-top: 30px;
}
.col-lg-4 {
  text-align: center;
  margin-top: 100px;
}
.txtname {
  font-weight: 1000;
  color: #1d5097;
}
.logo {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}
.img-thumbnail {
  border-radius: 20px;
  float: none;
  width: 200px;
  margin-bottom: 20px;
}
.mig-headings {
  font-size: 20pt;
  color: #1d5097;
  text-align: center;
  font-weight: 550;
  text-transform: uppercase;
}

@media (max-width: 600px) {
  .mig-headings {
    font-size: 17pt;
  }
  .imgHeading {
  font-size: 17pt;
  }
}
</style>
