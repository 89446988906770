<template>
  <div class="container">
    <h1 class="gallery-title">{{ $t("homeNav.nav_6") }}</h1>
    <div class="row">
      <div class="col-md" @click="openGallery('Migrationssymposion')">
        <img
          src="../assets/Events/Migrationssymposion/menu1.jpg"
          class="img-fluid"
          style="width: 100%"
        />
        <h4>{{ $t("gallery.heading_1") }}</h4>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md" v-on:click="openGallery('EmpfangbeimJustizminister')">
        <img
          src="../assets/Events/EmpfangbeimJustizminister/large/menubgJustiz.jpg"
          alt=""
          class="img-fluid"
        />
        <h4>{{ $t("gallery.heading_2") }}</h4>
      </div>
      <div
        class="col-md"
        v-on:click="openGallery('EmpfangimVerteidigungsministerium')"
      >
        <img
          src="../assets/Events/EmpfangbeimJustizminister/large/menu2.jpg"
          alt=""
          class="img-fluid"
        />
        <h4>{{ $t("gallery.heading_3") }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {


  
  methods: {
    openGallery(name) {
      this.$router.push({ name: "OpenGallery", params: { galleryName: name } });
    },
  },


};
</script>

<style scoped>
h4 {
  font-size: 20pt;
  color: #1d5097;
  font-weight: 600;
}
.col-md {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.gallery-title {
  font-size: 50pt;
  color: #1d5097;
  text-align: left;
  margin-top: 50px;
}
</style>