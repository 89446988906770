<template>
  <body style="background-image">
    <div class="container pt-3">
      <h1 class="greytxt">{{ $t("references.heading_2") }}</h1>
      <div class="container pt-3" id="root">
        <div class="row">
          <div id="imageBox">
            <img src="../assets/customers/burgenland.png" alt="burgenland" />
            <img src="../assets/customers/csc.png" alt="csc" />
            <img src="../assets/customers/generali.png" alt="generali" />
            <img src="../assets/customers/gkk.png" alt="gkk" />
            <img src="../assets/customers/ibm.png" alt="ibm" />

            <img
              src="../assets/customers/landesregierung.png"
              alt="landesregierung"
            />
            <img src="../assets/customers/linz.png" alt="linz" />
            <img src="../assets/customers/mmkarton.png" alt="mmkarton" />
            <img src="../assets/customers/ober.png" alt="ober" />
            <img src="../assets/customers/oraclelogo.png" alt="oraclelogo" />
            <img src="../assets/customers/post.png" alt="post" />
            <img src="../assets/customers/sv.png" alt="sv" />
            <img src="../assets/customers/tirol.png" alt="tirol" />
            <img src="../assets/customers/vkk.png" alt="vkk" />
            <img src="../assets/customers/wiener.png" alt="wiener" />
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<style scoped>
body {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../assets/backgrounds/test2.jpg);

  background-size: cover;
}
.greytxt {
  font-size: 50pt;
  color: white;
  margin-top: 40px;
}

#imageBox {
  display: flex;
  flex-wrap: wrap;
}

img {
  height: 130px;
  width: auto;
  margin: 1rem;
  animation-duration: 0.5s;
  padding: 5px;

  animation-name: descend;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  background-color: white;
  border-radius: 20px;
}

img:hover {
  animation-name: rise;
  animation-duration: 0.5s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

@keyframes rise {
  from {
    box-shadow: none;
  }
  to {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transform: scale(1.2);
  }
}

@keyframes descend {
  from {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transform: scale(1.2);
  }
  to {
    box-shadow: none;
    transform: scale(1);
  }
}

@media (max-width: 1000px) {
  .greytxt {
    font-size: 32pt;
  }
  img {
    height: 105px;
  }
}

@media (max-width: 600px) {
  .greytxt {
    font-size: 22pt;
    margin-top: 20px;
  }
  img {
    height: 95px;
  }
}

@media (max-width: 500px) {
  img {
    height: 80px;
  }
}
</style>