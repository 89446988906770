<template>
  <div class="container">
    <h1 class="mig-headings">
      {{ $t("migrationProjects.bundesheer.title") }}
    </h1>
    <p class="logo"><img src="../assets/logos/heer.png" alt="bundesheer" /></p>
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <p class="imgHeading">
            {{ $t("migrationProjects.bundesheer.imageHeading1") }}
            <br />
          </p>
          <img
            src="../assets/mref/fotoprojektBMLV.png"
            alt="image"
            class="img-thumbnail"
          />
        </div>

        <div class="col-lg-6" id="cont1">
          <p class="imgHeading">
            {{ $t("migrationProjects.bundesheer.imageHeading2") }}
            <br />
          </p>
          <img
            src="../assets/mref/bmlveinladung.png"
            alt="image"
            class="img-thumbnail"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
</script>

<style scoped>


#cont1{
    margin-top: 0px;
}
.col-lg-6 {
  text-align: center;
  margin-top: 0px;
}
.imgHeading {
  text-align: center;
  font-size: 20pt;
  color: #1d5097;
  font-weight: 550;
  margin-top: 30px;
}
.logo {
  text-align: center;
  margin-top: 30px;
}
.img-thumbnail {
  border-radius: 20px;
  float: none;
  width: 1000px;
  margin-bottom: 0px;
}
.mig-headings {
  font-size: 20pt;
  color: #1d5097;
  text-align: center;
  font-weight: 550;
  text-transform: uppercase;
}

@media (max-width: 600px)  {
.mig-headings {
font-size: 17pt;
}

.imgHeading{
  font-size: 17pt;
}
}
</style>