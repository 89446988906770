<template>
  <div class="container">
    <p class="logo">
      <img src="../assets/logos/AMS-logo.png" alt="ams" />
    </p>
    <h1 class="mig-headings">
      {{ $t("migrationProjects.amsbrz.title") }}
    </h1>
    <p class="logo">
      <img src="../assets/logos/brzlogo3.png" alt="brz" />
    </p>
  </div>

  <table class="table table-bordered">
    <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Source environment: IBM OS/390</th>
        <th scope="col">Target Environment: UNIX</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">Hardware</th>
        <td>IBM z/OS</td>
        <td>Linux/Intel</td>
      </tr>
      <tr>
        <th scope="row">Operating System</th>
        <td>z/OS v 1.12</td>
        <td>SLES</td>
      </tr>
      <tr>
        <th scope="row">TP Monitor</th>
        <td>---</td>
        <td>---</td>
      </tr>
      <tr>
        <th scope="row">Database</th>
        <td>VSAM</td>
        <td>SHARK Migration Addon</td>
      </tr>
      <tr>
        <th scope="row">Files</th>
        <td>Fix / Variable etc</td>
        <td>Linux Filesystem und Erweiterungen</td>
      </tr>
      <tr>
        <th scope="row">Language</th>
        <td>PL/I</td>
        <td>C/C++ / SHARK Migration Architecture</td>
      </tr>
      <tr>
        <th scope="row">User Interface</th>
        <td>---</td>
        <td>---</td>
      </tr>
      <tr>
        <th scope="row">Cmd Language</th>
        <td>JCL</td>
        <td>neu gescriptet in Bash</td>
      </tr>
      <tr>
        <th scope="row">Job schedule</th>
        <td>TWS/OPC</td>
        <td>UC4: Global</td>
      </tr>
      <tr>
        <th scope="row">Sort tool</th>
        <td>JCL, IBM DFSORT</td>
        <td>C-Addons SHARK</td>
      </tr>
    </tbody>
  </table>
</template>


<style scoped>
.logo {
  text-align: center;
}

.mig-headings {
  font-size: 20pt;
  color: #1d5097;
  text-align: center;
  font-weight: 550;
  text-transform: uppercase;
}

table {
  width: 50%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
@media (max-width: 600px) {
  .mig-headings {
    font-size: 17pt;
  }
}
</style>