
<template>
  <div class="container">
    <h1 class="greytxt">{{ title }}</h1>
    <div id="gallery">
      <div class="m-2 imageBox" v-for="picture in images" :key="picture">
        <img
          v-bind:src="picture"
          v-on:click="imageClicked(picture)"
          alt=""
          class="img-fluid"
        />
      </div>
    </div>

    <div
      id="image-viewer"
      v-if="showModal"
      v-bind:class="{ 'image-viewer': showModal }"
    >
      <span class="close" v-on:click="close">&times;</span>
      <img
        class="modal-content"
        :src="currentImageClicked"
        id="full-image"
        v-on:click="close"
      />
    </div>
  </div>
</template>

<script>
// import $ from 'jquery';
export default {
  data() {
    return {
      title: "",
      images: [],
      currentImageClicked: "",
      showModal: false,
    };
  },
  mounted() {
    this.getAllPictures();
  },
  methods: {
    getAllPictures() {
      console.log(this.$route.params.galleryName);

      /* $(".images img").click(function(){
                $("#full-image").attr("src", $(this).attr("src"));
                $('#image-viewer').show();
            });

            $("#image-viewer .close").click(function(){
                $('#image-viewer').hide();
            }); */

      switch (this.$route.params.galleryName) {
        case "EmpfangimVerteidigungsministerium":
          this.images.push(
            require("../assets/Events/EmpfangimVerteidigungsministerium/einladung_fr.jpg")
          );
          for (let i = 1; i < 16; i++) {
            this.images.push(
              require("../assets/Events/EmpfangimVerteidigungsministerium/small/" +
                i +
                ".jpg")
            );
          }
          this.title = "Empfang im Verteidigungsministerium";
          break;
        case "EmpfangbeimJustizminister":
          for (let i = 1; i < 55; i++) {
            if (i === 6 || i === 38 || i === 40 || i === 50) continue;
            this.images.push(
              require("../assets/Events/EmpfangbeimJustizminister/small/" +
                i +
                ".jpg")
            );
          }
          this.title = "Empfang beim Justizminister";
          break;
        case "Migrationssymposion":
          this.images.push(
            require("../assets/Events/Migrationssymposion/small/firstpic.jpg")
          );
          for (let i = 1; i < 54; i++) {
            if (i === 2 || i === 36 || i === 40) continue;
            this.images.push(
              require("../assets/Events/Migrationssymposion/small/" +
                i +
                ".jpg")
            );
          }

          this.title = "Migrationssymposion";
          break;
      }
    },
    imageClicked(src) {
      this.currentImageClicked = src;
      this.showModal = true;
    },
    close() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped>

h1{
  font-size: 20pt;
  color: #1d5097;
  font-weight: 600;
}
#gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.imageBox {
  height: 166px;
  width: 250px;
  background-color: white;
  text-align: center;
  cursor: pointer;
}

.img-fluid {
  height: 166px;
  transition: 0.3s;
}

img:hover {
  opacity: 0.7;
}

/* The Modal (background) */
.image-viewer {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}
.modal-content {
  animation-name: zoom;
  animation-duration: 0.6s;
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.image-viewer .close {
  position: absolute;
  top: 100px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  z-index: 7;
}
.image-viewer .close:hover,
.image-viewer .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}
</style>