import {
  createRouter,
  createWebHistory,

} from 'vue-router'
import application from '../views/ApplicationForm.vue';
import careerHome from '../views/Career-Home.vue';
import homeTemplate from "../views/Home_template.vue";
import gallery from "../views/Gallery.vue";
import openGallery from "../views/OpenGallery.vue";
// import Apply from '../views/Apply.vue';

const routes = [{
    path: '/',
    name: 'Home',
    component: homeTemplate,
   
  },

  {
    path: '/gallery',
    name: 'Gallery',
    component: gallery,

  },

  {
    path: '/gallery/:galleryName',
    name: 'OpenGallery',
    component: openGallery,

  },
  {
    path: '/career',
    name: 'Career',
    component: careerHome,
  },
  {
    path: '/apply/:id',
    name: 'Application',
    component: application
  }




  // {
  //   path: '/apply',
  //   name: 'Apply',
  //   component: Apply
  // }


]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),

  history: createWebHistory(),
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth"
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return {
        top: 0
      };
    }
  }


})
router.replace({
  path: '*',
  redirect: '/home'
})
export default router